<template>
    <div class="SunmmaryTeacherInfoDialog">
        <el-dialog
            title="教师档案预览"
            :visible="exportTeacherInfoDialog"
            @close="close"
            width="1100px"
            :modal-append-to-body="false"
            center
        >
            <div
                class="dialog-submit"
                @click="pdfDownload()"
            >
                导出pdf
            </div>
            <div
                id="pdfDom"
                ref="orderForm1"
            >
                <el-form
                    v-loading="loading"
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                >
                    <div class="basic-form basic-form-view" v-if="basicInfoViewFlag">
                        <div class="view-title">
                            <div class="view-flag"></div>
                            <div class="title">个人信息</div>
                        </div>
                        <div class="baseMain">
                            <div class="rt">
                                <el-row>
                                    <el-col :span="12">
                                        <el-row>
                                            <el-col :span="8">
                                                <div
                                                    class="baseCol baseCol-checked"
                                                >
                                                    登陆账号
                                                </div>
                                            </el-col>
                                            <el-col :span="16">
                                                <div
                                                    class="baseCol baseCol-first"
                                                >
                                                    {{ ruleForm.loginName }}
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="8">
                                                <div
                                                    class="baseCol baseCol-top baseCol-checked"
                                                >
                                                    登录密码
                                                </div>
                                            </el-col>
                                            <el-col :span="16">
                                                <div
                                                    class="baseCol baseCol-first baseCol-top"
                                                >
                                                    <span
                                                        v-for="ps in ruleForm
                                                            .password.length"
                                                        :key="ps"
                                                        >●</span
                                                    >
                                                </div>
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :span="8">
                                                <div
                                                    class="baseCol baseCol-top baseCol-checked"
                                                >
                                                    角色组
                                                </div>
                                            </el-col>
                                            <el-col :span="16">
                                                <div
                                                    class="baseCol baseCol-first baseCol-top"
                                                >
                                                    <span
                                                        class="post-item"
                                                        v-for="roleName in showRoleList"
                                                        :key="roleName"
                                                        >{{ roleName }}</span
                                                    >
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-row>
                                            <el-col :span="8">
                                                <div
                                                    class="baseCol baseCol-img baseCol-checked"
                                                >
                                                    头像
                                                </div>
                                            </el-col>
                                            <el-col :span="16">
                                                <div class="lt">
                                                    <img
                                                        :src="
                                                            ruleForm.headImg ||
                                                            uploadAvatar
                                                        "
                                                        class="upload-img"
                                                    />
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            教师姓名
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            {{ ruleForm.teacherName }}
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked"
                                        >
                                            手机号
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            {{ ruleForm.phone }}
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            工号
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            {{ ruleForm.onlyCode }}
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked"
                                        >
                                            性别
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <div v-if="ruleForm.sex == 1">
                                                男
                                            </div>
                                            <div v-else>女</div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            所属部门
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <template
                                                v-if="ruleForm.orgList.length"
                                            >
                                                <span
                                                    v-for="item in ruleForm.orgList"
                                                    :key="item.id"
                                                    class="post-item"
                                                    >{{ item.name }}</span
                                                >
                                            </template>
                                            <template v-else>
                                                <div></div>
                                            </template>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked"
                                        >
                                            是否任课教师
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <div
                                                v-if="
                                                    ruleForm.isSubjectTeacher ==
                                                    1
                                                "
                                            >
                                                是
                                            </div>
                                            <div v-else>否</div>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            是否班主任
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <div
                                                v-if="
                                                    ruleForm.isHeadmaster == 1
                                                "
                                            >
                                                是
                                            </div>
                                            <div v-else>否</div>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked"
                                        >
                                            班主任负责班级
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <template
                                                v-if="
                                                    ruleForm.manageClassList
                                                        .length
                                                "
                                            >
                                                <el-popover
                                                    placement="top-start"
                                                    title="班主任负责班级"
                                                    width="200"
                                                    trigger="hover"
                                                    v-if="
                                                        ruleForm.manageClassList
                                                            .length > 6
                                                    "
                                                >
                                                    <span
                                                        v-for="item in ruleForm.manageClassList"
                                                        :key="item.id"
                                                        class="post-item"
                                                        >{{ item.name }}</span
                                                    >
                                                    <div
                                                        slot="reference"
                                                        class="refer"
                                                    >
                                                        ...
                                                    </div>
                                                </el-popover>
                                                <span
                                                    v-for="(
                                                        cl, c
                                                    ) in ruleForm.manageClassList"
                                                    :key="c"
                                                    class="post-item"
                                                    >{{ cl.name }}</span
                                                >
                                            </template>
                                            <template v-else>
                                                <div></div>
                                            </template>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            可查看班级数据
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <template
                                                v-if="ruleForm.viewList.length"
                                            >
                                                <el-popover
                                                    placement="top-start"
                                                    title="可查看班级数据"
                                                    width="200"
                                                    trigger="hover"
                                                    v-if="
                                                        ruleForm.viewList
                                                            .length > 13
                                                    "
                                                >
                                                    <span
                                                        v-for="item in ruleForm.viewList"
                                                        :key="item.id"
                                                        class="post-item"
                                                        >{{ item.name }}</span
                                                    >

                                                    <div
                                                        slot="reference"
                                                        class="refer"
                                                    >
                                                        ...
                                                    </div>
                                                </el-popover>
                                                <span
                                                    v-for="item in ruleForm.viewList"
                                                    :key="item.id"
                                                    class="post-item"
                                                    >{{ item.name }}</span
                                                >
                                            </template>
                                            <template v-else>
                                                <div></div>
                                            </template>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked"
                                        >
                                            任课科目
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <span
                                                v-for="(
                                                    sub, subi
                                                ) in subJectList"
                                                :key="subi"
                                                class="post-item"
                                                >{{ sub }}</span
                                            >
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            职务
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            <template
                                                v-if="showPostList.length"
                                            >
                                                <span
                                                    v-for="(
                                                        post, posti
                                                    ) in showPostList"
                                                    :key="posti"
                                                    class="post-item"
                                                    >{{ post }}</span
                                                >
                                            </template>
                                            <template v-else>
                                                <div></div>
                                            </template>
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-second baseCol-checked"
                                        >
                                            入职时间
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="baseCol baseCol-second">
                                            {{
                                                ruleForm.entryTime.substring(
                                                    0,
                                                    10,
                                                )
                                            }}
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="4">
                                        <div
                                            class="baseCol baseCol-top baseCol-checked"
                                        >
                                            部门数据权限
                                        </div>
                                    </el-col>
                                    <el-col :span="20">
                                        <div class="baseCol baseCol-second">
                                            <template
                                                v-if="ruleForm.powerList.length"
                                            >
                                                <span
                                                    v-for="power in ruleForm.powerList"
                                                    :key="power.id"
                                                    class="post-item"
                                                    >{{ power.name }}</span
                                                >
                                            </template>
                                            <template v-else> </template>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </el-form>

                <!-- 档案 -->
                <div
                    v-if="
                        oriBasicForm.id &&
                        previewPdfList.archivesTypeIds.length > 0
                    "
                    class="detail-form"
                >
                    <StuInfoFillMain
                        ref="stuInfoFill"
                        type="teacher"
                        :mode="'view'"
                        :school-id="$store.state.schoolId"
                        :stu-basic-info="oriBasicForm"
                        :templateInfo="newOriBasicForm"
                    />
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {MTreeSelect, StuInfoFillMain} from "common-local";
import {listToTree} from "@/libs/utils.js";
import {getToken} from "@/libs/auth";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
export default {
    name: "SunmmaryTeacherInfoDialog",
    components: {
        MTreeSelect,
        StuInfoFillMain,
    },
    props: {
        basicInfoViewFlag: {
            type: Boolean,
            default: false,
        },
        previewPdfList: {
            type: Object,
        },
        exportTeacherInfoDialog: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            htmlTitle: "教师档案",
            ruleForm: {
                teacherName: "",
                remark: "",
                id: "",
                schoolId: "",
                phone: "",
                onlyCode: "",
                status: 1,
                sex: "1",
                classType: 1,
                post: "",
                postName: "",
                orgList: [],
                headImg: "",
                isHeadmaster: "2",
                isSubjectTeacher: "2",
                subjectClassList: [],
                manageClassList: [],
                viewList: [],
                subjectName: "",
                subject: "",
                loginName: "",
                password: "",
                roleIdList: [],
                entryTime: "",
                powerOrganList: [],
                powerList: [],
            },
            rules: {
                teacherName: [
                    {
                        required: true,
                        message: "请输入教师姓名",
                        trigger: ["change", "blur"],
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (
                                !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
                                    value,
                                )
                            ) {
                                callback("手机号不正确");
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                onlyCode: [
                    {
                        required: true,
                        message: "请输入工号",
                        trigger: "blur",
                    },
                ],
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
                isSubjectTeacher: [
                    {
                        required: true,
                        message: "请选择是否是任课教师",
                        trigger: "change",
                    },
                ],
                isHeadmaster: [
                    {
                        required: true,
                        message: "请选择是否是班主任",
                        trigger: "change",
                    },
                ],
                orgList: [
                    {
                        required: true,
                        message: "请选择所属部门",
                        trigger: "blur",
                    },
                ],
                subjectClassList: [
                    {
                        required: true,
                        message: "请选择任教班级",
                        trigger: "blur",
                    },
                ],
                subject: [
                    {
                        required: true,
                        message: "请选择任教科目",
                        trigger: "blur",
                    },
                ],
                manageClassList: [
                    {
                        required: true,
                        message: "请选择班主任负责班级",
                        trigger: "blur",
                    },
                ],
                loginName: [
                    {
                        required: true,
                        message: "请输入登录账号",
                        trigger: "blur",
                    },
                    {
                        validator: (rule, value, callback) => {
                            let newValue = value.replace(
                                /[`¥~!@#$%^&*\+ =<>?"{}|, \/ ;' \\ [ \] ·~！@#￥%……&*\+ ={}|《》？：“”【】、；‘’，。、_]/g,
                                "",
                            );
                            if (newValue !== value) {
                                callback("禁止输入特殊符号");
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入登密码",
                        trigger: "blur",
                    },
                    {
                        pattern:
                            /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}/,
                        message:
                            "必须包含大小写字母，特殊符号和数字且长度大于8位!",
                    },
                ],
                userName: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
            },
            loading: true,
            organList: [],
            options: [],
            options2: [],
            subjects: [],
            postList: [],
            showPostList: [],
            oriBasicForm: {},
            detailFormShow: false,
            roleList: [],
            disabledPassword: false,
            teacherRoleList: [],
            subJectList: [],
            exportDialog: true,
            exportForm: {
                archivesId: "",
                peopleId: "",
                schoolId: "",
            },
            newRoleList: [], //角色组的数据
            showRoleList: [],
            newOriBasicForm: [], // 详情页面回显角色组数据
        };
    },
    watch: {
        exportTeacherInfoDialog: {
            handler(nv) {
                if (nv == true) {
                    this.init();
                    this.getExportData();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        triangleUp() {
            return require("@/assets/images/stuInfoTemplate/triangle-up.png");
        },
        triangleDown() {
            return require("@/assets/images/stuInfoTemplate/triangle-down.png");
        },
        uploadAvatar() {
            return require("@/assets/images/upload-avatar.png");
        },
        action() {
            return "/api/school/common/uploadFile";
        },
        delUrl() {
            return require("@/assets/images/school-info-del.png");
        },
        infoUrl() {
            return require("@/assets/images/school-info-icon.png");
        },
        moveActiveUrl() {
            return require("@/assets/images/school-info-move.png");
        },
        moveUrl() {
            return require("@/assets/images/school-info-moveN.png");
        },
        archivesCompletenessUrl() {
            return require("@/assets/images/archivesCompleteness.png");
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    methods: {
        pdfDownload() {
            let that = this;
            this.close();
            const mainRight = document.getElementById("pdfDom");
            const _domInfo = mainRight.getBoundingClientRect();
            const imgs = mainRight.getElementsByTagName("img");
            console.log(_domInfo, "_domInfo");
            for (let img of imgs) {
                img.setAttribute("crossOrigin", "Anonymous");
            }
            // return
            html2canvas(mainRight, {
                allowTaint: true,
                useCORS: true,
                scale: 2, // 提升画面质量，但是会增加文件大小
                width: _domInfo.width,
                height: _domInfo.height,
                windowWidth: _domInfo.width,
                windowHeight: _domInfo.height,
            }).then(function (canvas) {
                console.dir(canvas, "canvas");
                var contentWidth = canvas.width;
                var contentHeight = canvas.height;
                console.log(contentWidth, contentHeight);
                let pageData = canvas.toDataURL("image/jpeg", 0.4);

                let overlayVal = 200
                if (contentHeight < 1788) {
                    overlayVal = 1988 - contentHeight;
                }
                var pdfWidth = ((contentWidth + 10) / 2) * 0.75;
                // var pdfWidth = (contentWidth + 10) / 2 * 0.4;
                var pdfHeight = ((contentHeight + overlayVal) / 2) * 0.75; // 500为底部留白

                var imgWidth = pdfWidth;
                var imgHeight = (contentHeight / 2) * 0.75; //内容图片这里不需要留白的距离
                let pdf = new JsPDF("", "pt", [pdfWidth, pdfHeight]);
                pdf.addImage(pageData, "jpeg", 55, 0, imgWidth, imgHeight);

                pdf.save("教师档案" + ".pdf");
            });
        },

        getExportData() {
            console.log(
                this.previewPdfList.archivesTypeIds,
                "this.previewPdfList",
            );
            if (this.previewPdfList.archivesTypeIds.length > 0) {
                this.previewPdfList.archivesTypeIds =
                    this.previewPdfList.archivesTypeIds.join(",");
                this._fet(
                    "/school/schoolArchivesData/getExportData",
                    this.previewPdfList,
                ).then((res) => {
                    if (res.data.code == "200") {
                        this.newOriBasicForm = res.data.data;
                    }
                });
            }
        },
        close() {
            this.$emit("closeTeacherDialog");
        },
        async init() {
            console.log(this.query, "this.query");
            this.loading = true;
            this.ruleForm.schoolId = this.$store.state.schoolId;
            await this.getDictList();
            await this.getOrgList();
            await this.getRoleList();
            await this.getTeacherInfo();
            // await this.getDepartmentOrgan();
            // await this.getNewRoleList();
            this.loading = false;
            this.showPostList = [];
            this.showRoleList = [];
            this.newRoleList.map((item) => {
                this.ruleForm.roleIdList.map((i) => {
                    if (i == item.id) {
                        this.showRoleList.push(item.roleName);
                    }
                });
            });
            this.postList.map((item, index) => {
                if (this.ruleForm.post && this.ruleForm.post.length > 0) {
                    this.ruleForm.post.map((i) => {
                        if (i == item.value) {
                            this.showPostList.push(item.label);
                        }
                    });
                }
            });
            // showRoleList

            this.roleList.map((item) => {
                if (item.id == this.ruleForm.roleId) {
                    this.teacherRoleList.push(item.roleName);
                }
            });
            this.subjects.map((item) => {
                if (item.value == this.ruleForm.subject) {
                    this.subJectList.push(item.label);
                }
            });
            console.log(this.options, "this.options");
        },
        /**
         * @Descripttion: 获取部门树形列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-04 15:25:27
         */
        async getDepartmentOrgan() {
            let res = await this._fet(
                "/school/schoolOrgan/getDepartmentOrgan",
                {
                    schoolId: this.$store.state.schoolId,
                },
            );
            if (res.data.code == "200") {
                this.organList = res.data.data;
            }
            console.log(this.organList, "this.organList");
        },
        /**
         * @Descripttion: 获取角色列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-04 15:53:16
         */
        async getNewRoleList() {
            let res = await this._fet("/school/schoolRole/listByCondition", {
                schoolId: this.$store.state.schoolId,
            });
            if (res.data.code == "200") {
                this.newRoleList = res.data.data;
            }
            console.log(this.newRoleList, "getNewRoleList");
        },
        /**
         * @Descripttion: 部门数据权限修改
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-04 11:15:20
         */
        handlerPowerOrganListChange(value) {
            this.ruleForm.powerOrganList.push(value);
            console.log(this.ruleForm, "handlerPowerOrganListChange");
        },
        handleCheckedCitiesChange(value) {
            console.log(
                this.ruleForm.roleIdList,
                "this.ruleFormhandleCheckedCitiesChange",
            );
            console.log(value, "handleCheckedCitiesChange");
        },
        async getTeacherInfo() {
            // 添加时登录密码/所属角色默认值
            let r = await this._fet(
                `/school/schoolTeacher/getTeacherInfo/${this.previewPdfList.peopleId}`,
            );
            if (r.data.code === "200") {
                let detail = r.data.data;
                console.log("[getTeacherInfo]", this.ruleForm,detail);
                setTimeout(() => {
                    Object.keys(detail).forEach((subKey) => {
                        this.ruleForm[subKey] = detail[subKey] ? detail[subKey] : ''
                    })
                    this.ruleForm['orgList'] = detail.deptList;
                    this.ruleForm['manageClassList'] = detail.masterClassList;

                    if (this.ruleForm.post && this.ruleForm.post.length > 0) {
                        this.ruleForm.post = this.ruleForm.post.split(",");
                    }
                    this.oriBasicForm = JSON.parse(JSON.stringify(this.ruleForm));
                    console.log(this.oriBasicForm, "this.oriBasicForm----))))");
                })

            } else {
                this.$message.error(r.data.msg);
            }
            // 编辑的时候如果有密码，不校验密码格式，保存的时候置空，并且不可编辑
            if (this.ruleForm.password) {
                this.disabledPassword = true;
                this.rules.password = [
                    {
                        required: true,
                        message: "请输入登密码",
                        trigger: "blur",
                    },
                ];
            }
        },
        async getOrgList() {
            let res = await this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.$store.state.schoolId,
            });
            if (res.data.code === "200") {
                const list = res.data.data.list;
                const list1 = list.filter((it) => it.organType == 1);
                const list2 = list.filter((it) => it.organType != 1);
                // list2.forEach((item) => {
                //   item.readOnly = item.organType !== '4'
                // })
                this.options = listToTree(list1, {
                    parentKey: "parentOrg",
                });
                this.options2 = listToTree(list2, {
                    parentKey: "parentOrg",
                });
            } else {
                this.$message.error(res.data.msg);
            }
        },
        async getDictList() {
            let res = await this._fet(
                "/school/schoolDictValue/listByCondition",
                {
                    dictKey: "teachingPost",
                    schoolId: this.$store.state.schoolId,
                },
            );
            if (res.data.code === "200") {
                this.postList = res.data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
                this.postList.unshift({label: "全部", value: ""});
            } else {
                this.$message.error(res.data.msg);
            }

            let res2 = await this._fet(
                "/school/schoolDictValue/listByCondition",
                {
                    schoolId: this.$store.state.schoolId,
                    dictKey: "teachingSubjects",
                },
            );
            if (res2.data.code === "200") {
                this.subjects = res2.data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            } else {
                this.$message.error(res2.data.msg);
            }
        },
        /**
         * @Description 获取角色下拉数据
         * @DoWhat 获取角色下拉数据
         * @UseScenarios 选择所属角色
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/8 17:04
         **/
        async getRoleList() {
            await this._fet("/school/schoolRole/getRoleList", {
                schoolId: this.$store.state.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.roleList = res.data.data;
                    console.log(this.roleList, "this.roleList");
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        filterFn(data) {
            return data.filter((i) => i.organType === "4");
        },
        postName(e) {
            this.$nextTick(() => {
                this.ruleForm.postName = this.$refs.postSelect.selected
                    .map((i) => i.label)
                    .join(",");
            });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(this.ruleForm, "this.ruleForm");
                    this.addNewSector(this.ruleForm);
                } else {
                    return false;
                }
            });
        },
        addNewSector(ruleForm) {
            let form = JSON.parse(JSON.stringify(ruleForm));
            if (!form.id) {
                delete form.id;
            }
            form.post = form.post.toString();
            // 获取科目名称
            let find = this.subjects.find((i) => i.value === form.subject);
            if (find) {
                form.subjectName = find.label;
            }
            if (form.isSubjectTeacher === "2") {
                form.subjectClassList = [];
                form.subjectName = "";
                form.subject = "";
            }
            if (form.isHeadmaster === "2") {
                form.manageClassList = [];
            }
            if (this.disabledPassword) {
                form.password = "";
            }
            console.log(form, "roleIdList");
            this._fet("/school/schoolTeacher/save", form).then((res) => {
                console.log("[保存]", res);
                if (res.data.code == "200") {
                    this.oriBasicForm = res.data.data;
                    this.$message.success("成功");
                    // 修改教师信息之后，重新调用getUserInfo，刷新保存的个人信息
                    this.$store.dispatch("setUserRouters");
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        // 展示或隐藏档案表单
        showStowDetailForm() {
            this.detailFormShow = !this.detailFormShow;
        },
        /**
         * @Description 上传头像成功回调
         * @DoWhat 上传头像成功回调
         * @UseScenarios 上传头像成功回调
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/10 15:05
         **/
        handleAvatarSuccess(res, file) {
            this.ruleForm.headImg = res.data.url;
        },
        /**
         * @Description 上传头像校验
         * @DoWhat 上传头像校验
         * @UseScenarios 上传头像校验
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/10 15:04
         **/
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;
            const is50 = file.name.length < 50;
            if (!is50) {
                this.$message.error("上传图片名称不能超过50个字符!");
            }
            if (!isJPG && !isPNG) {
                this.$message.error("上传图片必须是JPG或PNG格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过2MB!");
            }
            return (isJPG || isPNG) && isLt2M && is50;
        },
        /**
         * @Description 修改手机号
         * @DoWhat 登录名默认为手机号
         * @UseScenarios 修改手机号
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/10 15:02
         **/
        changePhone(value) {
            this.ruleForm.loginName = value;
        },
    },
    created() {
        // console.log(this.previewPdfList, 'previewPdfList');
    },
};
</script>

<style lang="scss" scoped>
.basic-title {
    font-size: 16px;
}
.dialog-submit {
    width: 120px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border: 1px solid #3C7FFF;
    border-radius: 4px;
    background: #3C7FFF;
    color: #ffffff;
    cursor: pointer;
    margin-left: 20px;
}
#pdfDom {
    padding: 20px;
    box-sizing: border-box;
}
.line-ti {
    line-height: 40px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
}

.page {
    padding: 16px;
    height: calc(100vh - 140px);
    // display: flex;
    // flex-direction: column;
    overflow-y: auto;
}

.exportDialog {
    margin-top: 10px;

    .exportDialog-title {
        display: flex;
        align-items: center;

        .exportDialog-text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #363a40;
            margin-right: 16px;
        }

        .exportDialog-reset {
            width: 72px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #e8f1fc;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3C7FFF;
        }
    }

    .exportDialog-content {
        width: 544px;
        background: #ffffff;
        border: 1px solid #edeff2;
        border-radius: 2px;
        margin-top: 20px;

        .exportDialog-content-title {
            display: flex;
            line-height: 40px;
            background: #f5f7fa;

            .exportDialog-content-sort {
                width: 80px;
                border-right: 1px solid #edeff2;
                text-align: center;
            }

            .exportDialog-content-name {
                flex: 1;
                padding-left: 20px;
                box-sizing: border-box;
            }
        }
        .exportDialog-content-title-sort {
            display: flex;
            line-height: 40px;
            .exportDialog-content-sort-title {
                width: 80px;
                border-right: 1px solid #edeff2;
                border-bottom: 1px solid #edeff2;
                text-align: center;
            }

            .exportDialog-content-name-text {
                flex: 1;
                padding-left: 20px;
                box-sizing: border-box;
                border-bottom: 1px solid #edeff2;
            }
        }
    }

    .exportDialog-list {
        display: flex;
        background-color: #fff;
        height: 40px;
        line-height: 40px;

        .exportDialog-sort {
            width: 80px;
            height: 40px;
            line-height: 40px;
            border-right: 1px solid #edeff2;
            border-bottom: 1px solid #edeff2;
            text-align: center;

            &:last-child {
                border-bottom: 0;
            }
        }

        .exportDialog-name {
            flex: 1;
            height: 40px;
            line-height: 40px;
            display: flex;
            border-bottom: 1px solid #edeff2;
            position: relative;

            .exportDialog-name-info {
                margin-left: 20px;
            }

            .exportDialog-icon {
                position: absolute;
                right: 20px;
                top: 14px;
                display: flex;

                .move-icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 26px;
                    display: block;
                }

                .del-icon {
                    width: 12px;
                    height: 12px;
                    display: block;
                }
            }
        }
    }

    .exportDialog-info {
        margin-top: 20px;
        height: 14px;
        line-height: 14px;
        display: flex;

        .dialog-info-icon {
            width: 13px;
            height: 13px;
        }

        .dialog-info-text {
            margin-left: 5px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f56c6c;
        }
    }

    .dialog-footer {
        display: flex;
        // margin: 0 auto;
        margin-top: 26px;

        .dialog-cancel,
        .dialog-submit {
            width: 120px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border: 1px solid #3C7FFF;
            border-radius: 4px;
            color: #3C7FFF;
        }

        .dialog-submit {
            background: #3C7FFF;
            color: #ffffff;
            margin: 0 24px 0 140px;
        }
    }
}

.archivesCompleteness {
    width: 100%;
    height: 40px;
    line-height: 40px;
    // border: 1px solid black;
    background: #edf4fa;
    margin-top: 32px;
    display: flex;
    align-items: center;

    .archivesCompleteness-icon {
        display: inline-block;
        margin-left: 12px;
        width: 13px;
        height: 8px;
    }

    .archivesCompleteness-title {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6c84a6;
        margin: 0 10px;
    }

    .archivesCompleteness-value {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3C7FFF;
    }
}
.newRoleList {
    width: 100% !important;
}

// 公共-有分割线的标题
::v-deep .line-title {
    text-align: center;
    display: flex;
    flex-direction: row;
}

::v-deep .line-title:before {
    content: "";
    flex: 1 1;
    width: 200px;
    margin: auto 20px;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #ffffff, #ffffff),
        linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
    border-bottom: 1px solid;
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 100%
    );
}

::v-deep .line-title:after {
    content: "";
    flex: 1 1;
    width: 200px;
    margin: auto 20px;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #ffffff, #ffffff),
        linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
    border-bottom: 1px solid;
    border-image: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 100%
    );
}

// 公共-按钮
.btn {
    width: 88px;
    height: 36px;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
}

.btn-reset {
    background: #ebf1f7;
    color: #737373;
}

.btn-confirm {
    background: #3C7FFF;
    color: #ffffff;
}

// 基础信息
.basic {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px;
}

// 基础信息-头部
.basic-header,
.basic-header-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .basic-header-flag {
        width: 1px;
        height: 16px;
        background: #bfbfbf;
        margin: 0 20px;
    }

    .back {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        cursor: pointer;
    }

    .basic-title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .exportBtn {
        width: 80px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        background: #3C7FFF;
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
    }
}

.basic-header-view {
    position: absolute;
    top: 13px;
    left: 20px;
}

// 基础信息-表单
.basic-form,
.basic-form-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 56px 10px 40px 40px;

    .baseTitle {
        width: 100%;
        text-align: left;
        // border-bottom: 1px solid black;
    }

    .baseMain {
        display: flex;
    }

    .lt {
        /deep/.el-upload {
            text-align: left;
        }

        .upload-img {
            width: 104px;
            height: 104px;
            border-radius: 6px;
        }

        .upload-text {
            font-size: 12px;
            color: #bfbfbf;
            line-height: 18px;
            margin-top: 16px;
            white-space: nowrap;
        }
    }

    .rt {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            padding-left: 40px;
            box-sizing: border-box;
            width: 50%;

            /deep/ .el-form-item__label {
                padding-right: 0;
            }

            /deep/ .el-select {
                width: 100%;
            }
        }

        .div-divider {
            height: 1px;
            width: 100%;
            margin: 10px 0 24px 40px;
            background: #edf0f2;
        }
    }

    .tips {
        font-size: 12px;
        display: block;
        margin-top: -5px;
        height: 25px;
        color: #909399;
        white-space: nowrap;
    }
}

.basic-form-view {
    display: block;
    padding: 0;
    margin-top: 50px;

    // border: 1px solid black;
    .rt {
        width: 100%;
        display: block;
    }

    .lt {
        height: 124px;
        border: 1px solid #919599;
        border-left: 1px solid rgba(255, 255, 255, 0);

        .upload-img {
            display: block;
            height: 104px;
            width: 104px;
            border-radius: 8px;
            margin: 10px auto 0;
        }
    }
}

.tac {
    text-align: center;
}

// 展示或收起按钮
.show-stow-btn {
    margin-top: 10px;
    height: 48px;
    background: #f7fafc;
    border-radius: 6px 6px 0px 0px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #3C7FFF;
    cursor: pointer;

    img {
        width: 12px;
        height: 8px;
        margin-left: 8px;
        margin-bottom: 2px;
    }
}

// 组件组
::v-deep .comps-group-div {
    margin-top: 22px;

    .comps-group-item {
        padding: 0;
    }
}

// 更多信息表
.detail-form {
    background-color: #ffffff;
    border-radius: 0px 0px 6px 6px;
    // flex: 1;
}

.baseCol {
    border: 1px solid #919599;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #363b40;
    min-height: 42px;
    padding: 0 12px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.refer {
    position: absolute;
    top: 0;
    left: 302px;
    width: 20px;
    font-size: 14px;
    color: #363b40;
}
.baseCol-first {
    border-left: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-secondTitle {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-top: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-second {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-top: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-right {
    border-right: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-top {
    border-top: 1px solid rgba(255, 255, 255, 0);
}
.baseCol-img {
    border-left: 1px solid rgba(255, 255, 255, 0);
    height: 126px;
    line-height: 126px;
    text-align: center;
}
.baseCol-checked {
    background-color: #edf4fa;
}
.view-title {
    width: 100%;
    height: 14px;
    line-height: 14px;
    display: flex;
    margin: 20px 0 16px;
    .view-flag {
        width: 3px;
        height: 14px;
        background-color: #3C7FFF;
        margin-right: 8px;
    }
    .title {
        font-size: 16px;
        font-weight: bold;
    }
}
.post-item {
    margin: 0 5px;
}
.page-view {
    .basic {
        position: relative;
        margin: 0 auto;
        width: 1000px !important;
    }
    ::v-deep .stu-info-fill-main {
        padding: 0 0 24px 0 !important;
    }
    ::v-deep .el-form-item {
        margin-bottom: 0;
        border: 1px solid black;
        .explainText {
            display: none;
        }
        .el-checkbox-group {
            overflow-y: scroll;
        }

        .el-radio-group {
            width: 100%;
            line-height: 47px !important;
            height: 36px;
            overflow-y: scroll;
        }

        .el-form-item__label {
            text-align-last: justify; // 最后一行两端对齐
            text-align: justify; // 两端对齐
            padding: 0 12px;
            width: 166px !important;
        }

        .el-form-item__content {
            border-left: 1px solid black;
            // height: 36px;
            min-height: 36px;
            position: relative;
            margin-left: 166px !important;

            .explain {
                text-align: center !important;
                font-size: 14px !important;
                color: #363b40 !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .form-value-show-only,
            .el-radio,
            .el-checkbox-group,
            .explain {
                padding-left: 15px;
            }

            .el-checkbox-group {
                height: 36px;
            }

            .el-radio-group {
                height: 36px;
            }

            .upload-demo {
                padding-left: 5px;
                display: flex;
                align-items: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .avatar-uploader {
                padding: 5px 10px;
            }

            .input-div {
                position: absolute;
                height: 100%;

                .input {
                    .el-input__inner {
                        font-size: 14px;
                        color: #363b40;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }

    /deep/ .el-table--border,
    .el-table--group {
        border: 1px solid #919599 !important;
    }

    /deep/ .el-table th.el-table__cell {
        color: #363b40 !important;
        font-size: 14px;
        font-weight: 400;
        background-color: #edf4fa !important;
        border: 1px solid #919599 !important;
        border-top: 1px solid rgba(255, 255, 255, 0) !important;

        &:nth-child(n) {
            border-left: 1px solid rgba(255, 255, 255, 0) !important;
        }
    }

    /deep/ .el-table__body td.el-table__cell {
        border: 1px solid #919599 !important;
        border-top: 1px solid rgba(255, 255, 255, 0) !important;
        font-size: 14px;
        color: #363b40;

        &:nth-child(n) {
            border-left: 1px solid rgba(255, 255, 255, 0) !important;
        }
    }

    /deep/ .el-form-item {
        border: 1px solid #919599 !important;
        height: inherit !important;

        .el-input.is-disabled .el-input__inner {
            background-color: white !important;
            text-align: center;
        }

        .el-textarea.is-disabled .el-textarea__inner {
            background-color: white !important;
            font-size: 14px;
            color: 363b40;
        }

        .el-form-item__label {
            text-align: center;
            text-align-last: unset;
            font-size: 14px;
            color: #363b40;
            background-color: #edf4fa;
        }

        .el-form-item__content {
            border-left: 1px solid #919599 !important;
        }

        .avatar-uploader-icon {
            width: 148px !important;
            height: 148px !important;
            line-height: 148px !important;
        }

        .avatar-uploader {
            margin-top: 21px !important;
        }

        .el-upload {
            cursor: not-allowed !important;
        }
    }
}
</style>
<style lang="scss">
.page-view .el-input__inner::placeholder {
    color: white !important;
}

.page-view .el-textarea__inner::placeholder {
    color: white !important;
}
</style>
